import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  Box,
  InputLabel,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import InfoIcon from "../asset/InfoIcon.png";
import "./UserFiltering.css"; // Import a CSS file for styling
import { title16500 } from "../style";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffectOnce } from "react-use";
import { userManagementService } from "../service/userManagementService";
import { da } from "date-fns/locale";

interface HistoryFilteringProps {
  handleSearch: (data: {
    email: string;
    name: string;
    userPermission: string;
    affiliatedHospital: string;
    menu: string;
    selectedStartDate: string;
    selectedEndDate: string;
    selectedActivities: string[];
  }) => void;
  handleClearFiltering: () => void;
}
const HistoryFiltering: React.FC<HistoryFilteringProps> = ({
  handleSearch,
  handleClearFiltering,
}) => {
  // State for storing the values of the three filter fields
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [userPermission, setUserPermission] = useState("");
  const [userPermissionList, setUserPermissionList] = useState<any[]>([]);
  const [affiliatedHospital, setAffiliatedHospital] = useState("");
  const [affiliatedHospitalList, setAffiliateHospitalHospitalList] = useState<
    any[]
  >([]);
  const [menu, setMenu] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const activityList = [
    { label: "ดูรายการ" },
    { label: "แก้ไขรายการ"},
    { label: "ดาวน์โหลด"},
    { label: "ลบข้อมูล"}
  ];
  const menuList = [
    {
      id: 1,
      name: "DASHBOARD",
    },
    {
      id: 2,
      name: "ข้อมูลผู้ป่วย",
    },
    {
      id: 3,
      name: "จัดการผู้ใช้งาน",
    },
    {
      id: 4,
      name: "อนุมัติผู้ใช้งาน",
    },
    {
      id: 5,
      name: "จัดการสิทธิ์การใช้งาน",
    },
    {
      id: 6,
      name: "ประวัติการใช้งาน",
    },
    {
      id: 7,
      name: "รายงาน",
    },
    {
      id: 8,
      name: "ตั้งค่าข้อมูลหลัก",
    },
  ];

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const availableFilterList =
          await userManagementService.getAvailableLogFilter();
          console.log(availableFilterList.hospitalName)
        setAffiliateHospitalHospitalList(availableFilterList.hospitalName);
        setUserPermissionList(availableFilterList.permission);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
  });

  const handleStartedDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedStartDate(formattedDate);
  };

  const handleEndedDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedEndDate(formattedDate);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string
  ) => {
    if (event.target.checked) {
      setSelectedActivities((prev) => [...prev, label]);
    } else {
      setSelectedActivities((prev) =>
        prev.filter((activity) => activity !== label)
      );
    }
  };

  const handleClickSearch = () => {
    handleSearch({
      email,
      name,
      userPermission,
      affiliatedHospital,
      menu,
      selectedStartDate,
      selectedEndDate,
      selectedActivities,
    });
  };

  const handleClickClearFiltering = () => {
    return () => {
      setEmail("");
      setName("");
      setUserPermission("");
      setAffiliatedHospital("");
      setMenu("");
      setSelectedStartDate(dayjs().format("YYYY-MM-DD"));
      setSelectedEndDate(dayjs().format("YYYY-MM-DD"));
      setSelectedActivities([]);
      handleClearFiltering();
    };
  };

  return (
    <div>
      <Box sx={{ py: 2 }}>
        <Typography component={"span"} style={title16500}>
          ผู้ใช้งานและสิทธิ์
        </Typography>
        <Typography component={"span"} color={"#7A8688"} style={title16500}>
          {` / ประวัติการใช้งาน`}
        </Typography>
      </Box>
      <Box sx={{ py: 3, px: 3, bgcolor: "white" }}>
        <p
          style={{
            ...title16500,
            borderBottom: "2px solid #D8D8D8",
            marginTop: "0px",
          }}
        >
          <span style={{ borderBottom: "3px solid #DF5C8E", fontSize: "18px" }}>
            ประวัติการใช้งาน
          </span>
        </p>
        <Box>
          <Grid container columnGap={4} sx={{ my: 2 }} alignItems="stretch">
            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                ค้นหา{" "}
                <img src={InfoIcon} style={{ width: "15px", height: "15px" }} />
              </Typography>
              <TextField
                label="-- ระบุอีเมลที่ต้องการค้นหา --"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                ค้นหา ชื่อ-นามสกุล{" "}
                <img src={InfoIcon} style={{ width: "15px", height: "15px" }} />
              </Typography>
              <TextField
                label="-- ระบุคำที่ต้องการค้นหา --"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                โรงพยาบาล
              </Typography>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel>-- เลือกโรงพยาบาล --</InputLabel>
                <Select
                  label="Regular Dropdown"
                  value={affiliatedHospital}
                  onChange={(e) => setAffiliatedHospital(e.target.value)}
                  MenuProps={{
                    sx: {
                      zoom: 100 * (100 / 85) + "%",
                      top: "0px",
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: "250px",
                        scrollbarWidth: "thin",
                      },
                    },
                  }}
                >
                  {affiliatedHospitalList.map((hospital) => (
                    <MenuItem value={hospital} sx={{ fontSize: 14 }}>
                      {hospital}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                สิทธิ์การใช้งาน
              </Typography>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel>-- เลือกสิทธิ์การใช้งาน --</InputLabel>
                <Select
                  label="Regular Dropdown"
                  value={userPermission}
                  onChange={(e) => setUserPermission(e.target.value)}
                  MenuProps={{
                    sx: {
                      zoom: 100 * (100 / 85) + "%",
                      top: "0px",
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: "250px",
                        scrollbarWidth: "thin",
                      },
                    },
                  }}
                >
                  {userPermissionList.map((userPermission) => (
                    <MenuItem value={userPermission} sx={{ fontSize: 14 }}>
                      {userPermission}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                เมนู
              </Typography>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel>-- เลือกสิทธิ์การใช้งาน --</InputLabel>
                <Select
                  label="Regular Dropdown"
                  value={menu}
                  onChange={(e) => setMenu(e.target.value)}
                  MenuProps={{
                    sx: {
                      zoom: 100 * (100 / 85) + "%",
                      top: "0px",
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: "250px",
                        scrollbarWidth: "thin",
                      },
                    },
                  }}
                >
                  {menuList.map((menu) => (
                    <MenuItem value={menu.name} sx={{ fontSize: 14 }}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                วันที่เริ่มต้น
              </Typography>
              <DatePicker
                maxDate={dayjs()}
                value={selectedStartDate ? dayjs(selectedStartDate) : dayjs()}
                onAccept={(newValue) => handleStartedDateChange(newValue)}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    fontSize: 16,
                  },
                  width: "100%",
                }}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                วันที่สิ้นสุด
              </Typography>
              <DatePicker
                minDate={selectedStartDate ? dayjs(selectedStartDate) : dayjs()}
                maxDate={dayjs()}
                value={selectedEndDate ? dayjs(selectedEndDate) : dayjs()}
                onAccept={(newValue) => handleEndedDateChange(newValue)}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    fontSize: 16,
                  },
                  width: "100%",
                }}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={1} pt={4}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#DF5C8E",
                  marginRight: "10px",
                  height: "40px",
                  width: "100%",
                }}
                onClick={handleClickSearch}
              >
                ค้นหา
              </Button>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={1} pt={4}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#7A8688",
                  marginRight: "10px",
                  height: "40px",
                  width: "100%",
                }}
                onClick={handleClickClearFiltering()}
              >
                ล้างการค้นหา
              </Button>
            </Grid>
          </Grid>

          <Grid container columnGap={4} sx={{ my: 2 }} alignItems="stretch">
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                กิจกรรม
              </Typography>
              {activityList.map((activity, index) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={activity.label}
                      checked={selectedActivities.includes(activity.label)}
                      onChange={(event) =>
                        handleCheckboxChange(event, activity.label)
                      }
                    />
                  }
                  label={activity.label}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default HistoryFiltering;
