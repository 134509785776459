import { calculateBmi } from "./calculateBmi";

export const calculateEBMIL = (
  currentW: number,
  previousW: number,
  height: number
) => {
  const currentBMI = calculateBmi(currentW, height);
  const previousBMI = calculateBmi(previousW, height);
  const ebmil = ((previousBMI - currentBMI) / (previousBMI - 25)) * 100;
  return (previousW === 0 || currentW === 0 ) ? "-" : parseFloat(ebmil.toFixed(2));
};
