/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MenuAppBar from "../shared/MenuAppBar";
import VerticalTab from "../shared/VerticalTab";
import "./UserTab/UserTab.css"; // Import a CSS file for styling
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Modal,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  DialogActions,
  styled,
  Dialog,
  Grid,
  ThemeProvider,
  Pagination,
  createMuiTheme,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router";
import HistoryFiltering from "../shared/HistoryFiltering";
import CloseIcon from "@mui/icons-material/Close";
import { Layout } from "../Layout";
import { ErrorDialog } from "../components/dialog/ErrorDialog";
import { userManagementService } from "../service/userManagementService";
import { useEffectOnce } from "react-use";
import { MasterData, UserPermission } from "../type";
import { set } from "date-fns";
import { useSelector } from "react-redux";
import excel_logo from "../asset/excellogo.png";
import { HistoryDialog } from "./HistoryDialog";
import HistoryExcelExport from "./HistoryExcelExport";
import dayjs from "dayjs";
import { hi } from "date-fns/locale";

const theme = createMuiTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          "&.Mui-selected": {
            backgroundColor: "#DF5C8E",
            color: "#ffffff",
          },
        },
      },
    },
  },
});

type DateRange = {
  startDateTime: string | null;
  endDateTime: string | null;
};
export type SearchHistoryParam = {
  email: string | null;
  name: string | null;
  permissionName: string | null;
  hospitalName: string | null;
  menu: string | null;
  dateRange: DateRange;
  activities: string[] | null;
};
type HistoryLog = {
  email: String;
  name: String;
  hospitalName: String;
  permissionName: String;
  menu: String;
  activity: String;
  dateTime: String;
};
const History = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [originalUserList, setOriginalUserList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUsers, setCurrentUsers] = useState<any[]>([]);
  const usersPerPage = 10;

  const userPermissionStatus = useSelector(
    (state: any) => state.loginUser.userPermission
  );
  const loginUserId = useSelector((state: any) => state.loginUser.userId);
  const userLogPermissionExportAble =
    loginUserId === "1"
      ? true
      : userPermissionStatus?.userLogPermission?.exportAble;
  const [searchHistoryParam, setSearchHistoryParam] =
    useState<SearchHistoryParam>({
      email: null,
      name: null,
      permissionName: null,
      hospitalName: null,
      menu: null,
      dateRange: {
        startDateTime: null,
        endDateTime: null,
      },
      activities: null,
    });
  const [historyLogList, setHistoryLogList] = useState<HistoryLog[]>([]);
  const tableValues = [
    { name: "ลำดับ", align: "center", key: "index" },
    { name: "วันที่และเวลา", align: "center", key: "dateTime" },
    { name: "อีเมล", align: "center", key: "email" },
    { name: "ขื่อ-นามสกุล", align: "center", key: "name" },
    { name: "โรงพยาบาล", align: "left", key: "hospitalName" },
    { name: "สิทธิ์การใช้งาน", align: "left", key: "permissionName" },
    { name: "เมนู", align: "left", key: "menu" },
    { name: "กิจกรรม", align: "center", key: "activity" },
  ];
  const tableCellValues = [
    { name: "ลำดับ", align: "center", key: "index" },
    { name: "วันที่และเวลา", align: "center", key: "dateTime" },
    { name: "อีเมล", align: "center", key: "email" },
    { name: "ขื่อ-นามสกุล", align: "center", key: "name" },
    { name: "โรงพยาบาล", align: "left", key: "hospitalName" },
    { name: "สิทธิ์การใช้งาน", align: "left", key: "permissionName" },
    { name: "เมนู", align: "left", key: "menu" },
    { name: "กิจกรรม", align: "center", key: "activity" },
    { name: "ผู้อนุมัติแอคเคาท์", align: "center", key: "approveBy" },
    { name: "วันและเวลาที่อนุมัติ", align: "center", key: "approveDateTime" },
  ];
  const totalPages = Math.ceil(historyLogList.length / 10);
  const [isLoading, setIsLoading] = useState(true);

  const activityList = [
    { label: "ดูรายการ", name: "VIEW" },
    { label: "แก้ไข", name: "EDIT" },
    { label: "ดาวน์โหลด", name: "DOWNLOAD" },
    { label: "อื่นๆ", name: "other" },
  ];

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const historyLogList = await userManagementService.getHistoryLog(
          searchHistoryParam
        );
        setHistoryLogList(historyLogList);
        setOriginalUserList(historyLogList);
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    setIsLoading(true);
    fetchData().then(() => setIsLoading(false));
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setCurrentUsers(
          historyLogList.slice(
            (currentPage - 1) * usersPerPage,
            currentPage * usersPerPage
          )
        );
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData().then(() => setIsLoading(false));
  }, [currentPage, historyLogList]);

  const [selectedUser, setSelectedUser] = useState(null);
  const handleRowClick = (user: any) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleSearch = async (data: {
    email: string;
    name: string;
    userPermission: string;
    affiliatedHospital: string;
    menu: string;
    selectedStartDate: string;
    selectedEndDate: string;
    selectedActivities: string[];
  }) => {
    const {
      email,
      name,
      userPermission,
      affiliatedHospital,
      menu,
      selectedStartDate,
      selectedEndDate,
      selectedActivities,
    } = data;
    const searchParam = {
      email: email ? email : null,
      name: name ? name : null,
      permissionName: userPermission ? userPermission : null,
      hospitalName: affiliatedHospital ? affiliatedHospital : null,
      menu: menu ? menu : null,
      dateRange: {
        startDateTime: selectedStartDate
          ? dayjs(selectedStartDate).format("YYYY-MM-DDTHH:mm:ss")
          : null,
        endDateTime:
          selectedStartDate === selectedEndDate
            ? dayjs(selectedEndDate)
                .add(23, "hour")
                .add(59, "minute")
                .format("YYYY-MM-DDTHH:mm:ss")
            : selectedEndDate
            ? dayjs(selectedEndDate).format("YYYY-MM-DDTHH:mm:ss")
            : null,
      },
      activities: selectedActivities.length > 0 ? selectedActivities : null,
    };
    setSearchHistoryParam(searchParam);
    setIsLoading(true);
    try {
      const filteredUsers = await userManagementService.getHistoryLog(
        searchParam
      );
      // Process filteredUsers as needed
      setHistoryLogList(filteredUsers);
      setCurrentPage(1);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearFiltering = () => {
    setHistoryLogList(originalUserList);
    setCurrentUsers(
      originalUserList.slice(
        (currentPage - 1) * usersPerPage,
        currentPage * usersPerPage
      )
    );
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const generateFileName = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");

    return `Chula_COS_Loglist_${year}${month}${day}${hour}${minute}`;
  };

  return (
    <Layout activeTab="userPermission">
      <div className="user-filtering-container">
        <HistoryFiltering
          handleSearch={handleSearch}
          handleClearFiltering={handleClearFiltering}
        />
        <Box p={3} bgcolor={"white"} marginTop={2}>
          <div>
            {userLogPermissionExportAble && (
              <div
                style={{
                  display: "flex",
                  float: "right",
                  marginBottom: "20px",
                }}
              >
                <HistoryExcelExport
                  data={historyLogList}
                  columns={tableCellValues}
                  fileName={generateFileName()}
                  reportTitle="รายงานประวัติการใช้งาน"
                  reportDescription="This report contains the user history data."
                />
              </div>
            )}
          </div>
          <Box>
            <TableContainer component={Paper}>
              <Table className="pink-table-line">
                <TableHead>
                  <TableRow className="pink-bottom-line">
                    {tableValues.map((value) => (
                      <TableCell style={{ fontSize: "16px" }}>
                        {value.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentUsers.map((user, index) => (
                    <TableRow key={index} onClick={() => handleRowClick(user)}>
                      <TableCell>{(currentPage - 1) * usersPerPage + index + 1}</TableCell>
                      <TableCell>
                        {dayjs(user.dateTime?.toString()).format(
                          "DD/MM/BBBB HH:mm น."
                        )}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>
                        {user.hospitalName ? user.hospitalName : "-"}
                      </TableCell>{" "}
                      <TableCell>{user.permissionName}</TableCell>
                      <TableCell>{user.menu}</TableCell>
                      <TableCell>{user.activity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <HistoryDialog
                  open={open}
                  handleClose={handleClose}
                  user={selectedUser}
                />
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: isLoading ? "flex" : "none",
                justifyContent: "center",
                fontSize: 20,
                color: "#9AA1AE",
                py: 8,
                border: "1px solid #ddd", // Add this line
                borderRadius: "4px",
              }}
            >
              <CircularProgress />
              <Typography>กำลังโหลดข้อมูล...</Typography>
            </Box>
            {currentUsers.length === 0 && !isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 20,
                  color: "#9AA1AE",
                  py: 2,
                  border: "1px solid #ddd", // Add this line
                  borderRadius: "4px",
                }}
              >
                --ไม่พบผู้ใช้งาน--
              </Box>
            )}
          </Box>
          <ThemeProvider theme={theme}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "30px",
              }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </ThemeProvider>
        </Box>
      </div>
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
    </Layout>
  );
};

export default History;
